<fly-left-sidebar-layout [showSidebar]="!mobileView || showSidebar">
  <pilot-header
    slot="header"
    (sidebarVisibilityChange)="toggleSidebarVisibility($event)"></pilot-header>
  <pilot-sidebar
    slot="sidebar"
    class="no-select"
    [mobileView]="mobileView"></pilot-sidebar>
  <ng-container slot="content">
    <div class="router-outlet-wrapper">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</fly-left-sidebar-layout>
<fly-message-center
  flyToFrontOnInteract
  class="message-center"
  [style.z-index]="messageCenterZIndex"></fly-message-center>
